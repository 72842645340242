import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import SpacingBox from 'components/SpacingBox';
import Translations from 'components/Translations';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  SpacingBox,
  Translations,
  React
};