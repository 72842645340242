import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import { RqToc } from 'components/rq-toc';
import Aside from 'components/Aside';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  RqToc,
  Aside,
  React
};