import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Tweet, { AvatarWillMcGugan, AvatarTkDodo, AvatarT3dotgg, AvatarMichaelC } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Tweet,
  AvatarWillMcGugan,
  AvatarTkDodo,
  AvatarT3dotgg,
  AvatarMichaelC,
  React
};