import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Tweet, { AvatarMattpocockuk } from 'components/Tweet';
import { TweetPoll } from 'components/TweetPoll';
import { Alert } from '@theme-ui/components';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Tweet,
  AvatarMattpocockuk,
  TweetPoll,
  Alert,
  React
};