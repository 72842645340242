import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Emph from 'components/Emph';
import Highlight from 'components/Highlight';
import { Alert } from '@theme-ui/components';
import Aside from 'components/Aside';
import GifPlayer from 'components/GifPlayer';
import Tweet, { AvatarAcdlite } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Emph,
  Highlight,
  Alert,
  Aside,
  GifPlayer,
  Tweet,
  AvatarAcdlite,
  React
};