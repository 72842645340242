import * as React from 'react'
import { Box } from '@theme-ui/components'

const SpacingBox = () => (
  <Box as="p" sx={{ mb: 3 }}>
    {' '}
  </Box>
)

export default SpacingBox
