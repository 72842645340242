import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Tweet, { AvatarDiegohaz, TweetImage, UseEvent } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Tweet,
  AvatarDiegohaz,
  TweetImage,
  UseEvent,
  React
};