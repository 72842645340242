import Aside from 'components/Aside';
import Comments from 'components/Comments';
import Highlight from 'components/Highlight';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Emph from 'components/Emph';
import Tweet, { TweetImage, AvatarMxstbr, SeparationOfConcerns } from 'components/Tweet';
import * as React from 'react';
export default {
  Aside,
  Comments,
  Highlight,
  Attribution,
  Translations,
  Emph,
  Tweet,
  TweetImage,
  AvatarMxstbr,
  SeparationOfConcerns,
  React
};