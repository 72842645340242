import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import { RqToc } from 'components/rq-toc';
import Emph from 'components/Emph';
import Aside from 'components/Aside';
import Tweet, { AvatarAlexDotJs } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  RqToc,
  Emph,
  Aside,
  Tweet,
  AvatarAlexDotJs,
  React
};