import Comments from 'components/Comments';
import Translations from 'components/Translations';
import Tweet, { AvatarSophiebits } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Translations,
  Tweet,
  AvatarSophiebits,
  React
};