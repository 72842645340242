import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import { RqToc } from 'components/rq-toc';
import Emph from 'components/Emph';
import Aside from 'components/Aside';
import { QueryGG } from 'components/QueryGG';
import Highlight from 'components/Highlight';
import { Alert } from '@theme-ui/components';
import { VerticalRuler } from 'components/VerticalRuler';
import Tweet, { AvatarTkDodo, AvatarSophiebits, AvatarGabbeV_, TweetImage, SathyaAndMe, V5390 } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  RqToc,
  Emph,
  Aside,
  QueryGG,
  Highlight,
  Alert,
  VerticalRuler,
  Tweet,
  AvatarTkDodo,
  AvatarSophiebits,
  AvatarGabbeV_,
  TweetImage,
  SathyaAndMe,
  V5390,
  React
};