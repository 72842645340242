import Comments from 'components/Comments';
import Translations from 'components/Translations';
import { Presentation } from 'components/thinking-in-react-query';
import { RqToc } from 'components/rq-toc';
import * as React from 'react';
export default {
  Comments,
  Translations,
  Presentation,
  RqToc,
  React
};