import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Emph from 'components/Emph';
import { QueryGG } from 'components/QueryGG';
import Tweet, { AvatarTkDodo, TanStackQueryV5, SelfieInception, TweetImage } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Emph,
  QueryGG,
  Tweet,
  AvatarTkDodo,
  TanStackQueryV5,
  SelfieInception,
  TweetImage,
  React
};