import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Highlight from 'components/Highlight';
import Tweet, { AvatarTkDodo, AvatarSwyx, TweetImage, CynicsVsBuilders } from 'components/Tweet';
import { RqToc } from 'components/rq-toc';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Highlight,
  Tweet,
  AvatarTkDodo,
  AvatarSwyx,
  TweetImage,
  CynicsVsBuilders,
  RqToc,
  React
};