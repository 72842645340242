import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import { RqToc } from 'components/rq-toc';
import Emph from 'components/Emph';
import Aside from 'components/Aside';
import { QueryGG } from 'components/QueryGG';
import Highlight from 'components/Highlight';
import { Alert } from '@theme-ui/components';
import Tweet, { AvatarRalex1993, TweetImage, ContactDetailQuery } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  RqToc,
  Emph,
  Aside,
  QueryGG,
  Highlight,
  Alert,
  Tweet,
  AvatarRalex1993,
  TweetImage,
  ContactDetailQuery,
  React
};