import Aside from 'components/Aside';
import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Highlight from 'components/Highlight';
import * as React from 'react';
export default {
  Aside,
  Comments,
  Attribution,
  Translations,
  Highlight,
  React
};