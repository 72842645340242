import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import { RqToc } from 'components/rq-toc';
import Emph from 'components/Emph';
import Aside from 'components/Aside';
import { QueryGG } from 'components/QueryGG';
import { FixTitle } from 'components/FixTitle';
import { VerticalRuler } from 'components/VerticalRuler';
import Highlight from 'components/Highlight';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  RqToc,
  Emph,
  Aside,
  QueryGG,
  FixTitle,
  VerticalRuler,
  Highlight,
  React
};